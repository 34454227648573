import React, { useState, useEffect } from "react";

// Hooks
import { useSelector } from "react-redux";

// Utils
import { MESSAGES } from "~/utils/message";
import { dateFormat } from "~/utils/date-format";
import { isSlackWebhook } from "~/utils/helperFunctions";

// MUI Components
import { DataGrid } from "@mui/x-data-grid";
import { Box, Typography, Tooltip, Stack } from "@mui/material";

// Components
import Loader from "~/components/General/Loader";

// Icon
import { ArrowRightCIcon } from "~/assets/images";

// Styles
import "./styles.scss";

const WebhookLogTable = (props) => {
  const { rows, setSelectedWebhookLog, setDisplayLogPage } = props;
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const isLoading = useSelector((state) => state.webhooksReducers.loading);

  const handleRowClick = (params, event) => {
    setSelectedWebhookLog(params.row);
    setDisplayLogPage(true);
  };

  const getEvent = (row) => {
    if (isSlackWebhook(row?.value?.request?.url)) {
      return (
        JSON.parse(JSON.parse(row?.value?.request?.body)?.text)?.event || " -"
      );
    }
    return JSON.parse(row?.value?.request?.body)?.event || " -";
  };

  const columns = [
    {
      field: "name",
      headerName: "Event",
      width: 300,
      sortable: false,
      flex: window.innerWidth >= 1199 ? 2 : 0,
      renderCell: (params) => (
        <Box className="tagsWrapper">
          <Tooltip title={getEvent(params.row)}>
            <Typography>{getEvent(params.row)}</Typography>
          </Tooltip>
        </Box>
      ),
    },
    {
      field: "key",
      headerName: "Status Code",
      width: 220,
      sortable: false,
      flex: window.innerWidth >= 1199 ? 1 : 0,
      renderCell: (params) => (
        <Box className="tagsWrapper">
          <Tooltip title={params.row?.value?.response?.status || " -"}>
            <Typography>
              {params.row?.value?.response?.status || " -"}
            </Typography>
          </Tooltip>
        </Box>
      ),
    },
    {
      field: "date",
      headerName: "Date",
      width: 220,
      sortable: false,
      flex: window.innerWidth >= 1199 ? 1 : 0,
      renderCell: (params) => (
        <Box className="tagsWrapper">
          <Tooltip title={dateFormat(params.row?.createdAt) || " -"}>
            <Typography>
              {dateFormat(params.row?.createdAt) || " -"}
              <img className="arrow-right" src={ArrowRightCIcon} alt="icon" />
            </Typography>
          </Tooltip>
        </Box>
      ),
    },
  ];

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      style={{
        marginTop: "20px",
      }}
      className="webhookTableWrapper webhookLog"
    >
      <Typography className="title">
        {MESSAGES.SETTINGS.WEBHOOKS.LOGS.TITLE}
      </Typography>

      <DataGrid
        rows={rows}
        rowCount={rows?.length || 0}
        columns={columns}
        paginationMode="server"
        hideFooterSelectedRowCount
        loading={isLoading}
        hideFooterPagination
        rowSelection={false}
        onRowClick={handleRowClick}
        getRowId={(row) => row.id}
        disableColumnMenu={true}
        className="weblogTableGrid"
        components={{
          NoRowsOverlay: () => (
            <Stack className="noRowsTextlog">
              {MESSAGES.SETTINGS.WEBHOOKS.LOGS.NO_RESULT_TEXT}
            </Stack>
          ),
          loadingOverlay: Loader,
        }}
      />
    </div>
  );
};

export default WebhookLogTable;
