import React, { useEffect, useState } from 'react';

// Third Party Libraries
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

// Utils
import { percentager } from '~/utils/helperFunctions';

const PieChart = ({ info, deliverableQuantity }) => {
    const [data, setData] = useState([]);

    let pieData = {
        datasets: [
            {
                data: data,
                backgroundColor: [
                    'rgb(255 248 209)',
                    'rgb(255 192 184)',
                    'rgb(237 237 237)',
                    'rgb(255 194 148)',
                    'rgb(253, 236, 211)',
                ],
                hoverOffset: 2,
                borderWidth: 9
            },
        ],
        labels: [
            'Mailed',
            'In Transit',
            'Delivered',
            'Re-Routed',
            'Returned to Sender'
        ],
    };
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            datalabels: {
                formatter: function (value, context) {
                    return value + '%';
                },
                color: [
                    "#fdb515", "#d66353", "#989898", "#f28230"
                ],
                offset: -85,
                anchor: 'end',
                align: 'end',
                font: {
                    size: 20,
                    weight: 'bold'
                },
                padding: {
                    top: 10,
                    bottom: 10,
                },
                clip: true,  // Ensures labels are clipped if they are outside the chart area
                display: function (context) {
                    const dataset = context.dataset;
                    const value = dataset.data[context.dataIndex];
                    // Conditionally display labels based on value or index
                    // Only show labels for values greater than 5%
                    return value > 5;  
                }
            },
            legend: {
                display: false
            },

            tooltip: {
                title: {
                    display: false
                },
                backgroundColor: 'white',
                bodyColor: "black",
                padding: 10,
                cornerRadius: 3,
                displayColors: false,
                callbacks: {
                    label: function (context) {
                        let label = context.formattedValue || '';
                        let type = context.label || '';

                        if (label) {
                            label += `% ${type}`;
                        }

                        return label;

                    }
                }
            },
        },
    };

    useEffect(() => {
        let dataToRender = []
        dataToRender.push(percentager(info.orderItemStatuses['Mailed'], deliverableQuantity ));
        dataToRender.push(percentager(info.orderItemStatuses['In Transit'], deliverableQuantity ));
        dataToRender.push(percentager(info.orderItemStatuses['Delivered'], deliverableQuantity));
        dataToRender.push(percentager(info.orderItemStatuses['Re-Routed'], deliverableQuantity ));
        dataToRender.push(percentager(info.orderItemStatuses['Returned to Sender'],  deliverableQuantity))
        setData(dataToRender)

    }, [])

    return <Pie height={426} data={pieData} plugins={[ChartDataLabels]} options={options} />;
};

export default PieChart;