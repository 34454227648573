import React, { useEffect, useState, useRef } from "react";

//Hooks
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Actions
import { failure, success } from "~/redux/actions/snackbar-actions";
import { updateContactCustomFields } from "~/redux/actions/contacts-actions";

// Utils
import { MESSAGES } from "~/utils/message";

// MUI Components
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Box, Button, Grid, Typography, CircularProgress } from "@mui/material";

// Images
import { EditCDCIcon, ArrowDownCIcon } from "~/assets/images";

// Styles
import "./styles.scss";

const CustomFields = (props) => {
  const { contactCustomFields } = props;
  const [customFields, setCustomFields] = useState([]);
  const [modifiedCustomFields, setModifiedCustomFields] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [loader, setLoader] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const prevModifiedCustomFields = useRef(modifiedCustomFields);

  const params = useParams();
  const dispatch = useDispatch();

  const contactId = params.id;

  const allCustomFields = useSelector(
    (state) => state?.customFieldsReducers?.customFields
  );

  const handleEdit = () => {
    setIsEdit(true);
    setExpanded(true);
  };

  const handleAccordion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  const handleUpdateCustomFields = (label, value) => {
    const updatedCustomFields = customFields.map((field) =>
      Object.keys(field)[0] === label ? { [label]: value } : field
    );
    setCustomFields(updatedCustomFields);
  };

  const updateCustomFields = async () => {
    try {
      const payload = customFields.reduce((result, field) => {
        const [key] = Object.keys(field);
        result[key] = field[key];
        return result;
      }, {});

      setLoader(true);
      const response = await dispatch(
        updateContactCustomFields(contactId, {
          customFields: payload,
        })
      );
      if (response.status === 200) {
        dispatch(success(response.data.message));
        setModifiedCustomFields(
          Object.entries(payload).map(([label, value]) => ({ label, value }))
        );
      } else {
        dispatch(failure(response.data.message));
      }
    } catch (error) {
      dispatch(failure(error.message));
    } finally {
      setLoader(false);
      setIsEdit(false);
    }
  };

  useEffect(() => {
    setModifiedCustomFields(
      allCustomFields.map((allFields) => {
        return {
          label: allFields.value,
          value: contactCustomFields
            ? contactCustomFields[allFields?.value]
            : "",
        };
      })
    );
  }, [allCustomFields, contactCustomFields]);

  useEffect(() => {
    // Check if values in modifiedCustomFields have changed
    if (
      Object.keys(modifiedCustomFields).some(
        (key) =>
          modifiedCustomFields[key] !== prevModifiedCustomFields.current[key]
      )
    ) {
      setCustomFields(
        modifiedCustomFields.map((item) => ({
          [item.label]: item.value || "",
        }))
      );
    }

    // Update the reference to the current modifiedCustomFields for the next comparison
    prevModifiedCustomFields.current = modifiedCustomFields;
  }, [modifiedCustomFields, contactCustomFields, allCustomFields]);

  return (
    <Box className="customField">
      <Grid container mt={3}>
        <Grid item lg={2} md={2} sm={2} xs={2}></Grid>
        <Grid item lg={9} md={9} sm={9} xs={9}>
          <Accordion
            className="customFieldAccordion"
            expanded={expanded}
            onChange={handleAccordion}
          >
            <AccordionSummary
              id="panel-header"
              aria-controls="panel-content"
              className="accordionSummary"
            >
              <Typography className="title">
                {MESSAGES.SETTINGS.CUSTOM_FIELDS.TITLE}
                <img src={ArrowDownCIcon} alt="icon" />
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {/* default */}
              {!isEdit ? (
                <Grid container>
                  {modifiedCustomFields
                    .slice() // Create a copy of the array to avoid mutating the original array
                    .sort((a, b) => a.label.localeCompare(b.label)) // Sort the array alphabetically by label
                    .map((field, index) => (
                      <Grid item lg={12} md={12} sm={12} xs={12} key={index}>
                        <Grid container mb={1}>
                          <Grid item lg={4} md={4} sm={12} xs={12}>
                            <div className="label">{field.label}</div>
                          </Grid>
                          <Grid ml={1} item lg={7} md={7} sm={12} xs={12}>
                            <div className="editContent infoTxt">
                              <span>{field.value || " -"}</span>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                </Grid>
              ) : (
                <Grid container spacing={2}>
                  {modifiedCustomFields
                    .slice() // Create a copy of the array to avoid mutating the original array
                    .sort((a, b) => a.label.localeCompare(b.label)) // Sort the array alphabetically by label
                    .map((field, index) => (
                      <Grid item lg={12} md={12} sm={12} xs={12} key={index}>
                        <div className="cutomFieldEditWrapper">
                          <div className="label">{field.label}</div>
                          <input
                            placeholder={field.label}
                            defaultValue={field.value || ""}
                            onChange={(e) =>
                              handleUpdateCustomFields(
                                field.label,
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </Grid>
                    ))}
                </Grid>
              )}

              {/* Edit */}
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item lg={1} md={1} sm={1} xs={1}>
          <Box className="detailsGrid">
            <Box className="detailsActionBtn">
              {!isEdit ? (
                <Button className="editBtn" onClick={handleEdit}>
                  <img src={EditCIcon} alt="edit" />
                  <Typography>{MESSAGES.CONTACTS.EDIT_BTN_TEXT}</Typography>
                </Button>
              ) : (
                <Button className="delBtn" onClick={updateCustomFields}>
                  {loader ? (
                    <CircularProgress
                      sx={{
                        color: "white",
                        width: "25px !important",
                        height: "25px !important",
                      }}
                    />
                  ) : (
                    <Typography>{MESSAGES.CONTACTS.SAVE_BTN_TEXT}</Typography>
                  )}
                </Button>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CustomFields;
