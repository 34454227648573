import React, { useState } from "react";

// Hooks
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// Utils
import { balanceFormatter } from "~/utils/helperFunctions";
import { ADMIN_NAVIGATION, GENERAL_NAVIGATION } from "~/utils/constants";
import { getImpersonationMode } from "~/utils/auth";

// Components
import LimitModal from "~/components/General/LimitModal";
import DropDown from "~/components/Layout/Header/Dropdown";
import SwitchBar from "~/components/AdminPortal/Users/SwitchBar";
import Stripe from "~/components/Stripe/Stripe";
import AddFunds from "~/components/Account/Wallet/AddFunds";
import PaymentMethod from "~/components/Account/Wallet/PaymentMethod";
import Mode from "~/components/Mode";
import HelpDropdown from "~/components/Layout/Header/HelpDropdown";

// Mui Components
import {
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  Box,
  Button,
  Drawer,
  Divider,
  List,
  ListItem,
  Grid,
  Container,
  useMediaQuery,
} from "@mui/material";

// Icons
import { OpenLetterLogoCIcon, DropdownArrrowCIcon, Help2CIcon } from "~/assets/images";
import MenuIcon from "@mui/icons-material/Menu";

// Styles
import "./styles.scss";

const drawerWidth = 240;

const Header = (props) => {
  const location = useLocation();
  const { window, isAdminMode, onClick, isWidgetVisible, setIsWidgetVisible } = props;

  const [mobileOpen, setMobileOpen] = useState(false);
  // State for DropDown
  const [showDropDown, setShowDropDown] = useState(false);
  const [helpDropDown, setHelpDropDown] = useState(false);
  const [fundsModal, setFundsModal] = useState(false);
  const [paymentMehodModal, setPaymentMethodModal] = useState(false);

  const navigate = useNavigate();

  // handler for dropdown
  const handleShowDropDown = () => {
    setShowDropDown((prevState) => !prevState);
  };
  const handleDropDown = () => {
    setHelpDropDown((prevState) => !prevState);
  };
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const isAdmin =
    useSelector((state) => state.userReducers?.user?.isOrgAdmin) || false;

  const userName = useSelector(
    (state) => state.userReducers?.user?.fullName || ""
  );

  const userOrganization = useSelector(
    (state) => state.userReducers.user.organization || { balance: 0 }
  );

  const paymentMethods = useSelector((state) => state.stripeReducers?.methods);

  const isImpersonatedMode = getImpersonationMode() || false;

  const currentNavigation = isAdminMode ? ADMIN_NAVIGATION : GENERAL_NAVIGATION;

  const handlePaymentModal = () => {
    paymentMethods.length > 0
      ? setFundsModal(true)
      : setPaymentMethodModal(true);
  };

  const drawer = (
    <Box className="mobileDrawer" onClick={handleDrawerToggle}>
      <img src={OpenLetterLogoCIcon} alt="open-letter" />
      <Divider />
      <List className="mobileDrawerList">
        {currentNavigation.map((item) => {
          return (
            <NavLink
              key={item?.id}
              to={item?.link}
              className={({ isActive }) =>
                isActive || item.childrens.includes(location.pathname)
                  ? "activeItem"
                  : ""
              }
            >
              <ListItem>{item?.title}</ListItem>
            </NavLink>
          );
        })}
      </List>
      {!isAdminMode && (
        <Button
          onClick={isAdmin ? handlePaymentModal : undefined}
          className="balanceBtnNav"
        >
          {balanceFormatter(userOrganization.balance)}
        </Button>
      )}
    </Box>
  );
  const container =
    window !== undefined ? () => window().document.body : undefined;
  // Use the useMediaQuery hook to check the screen size
  const isXlScreen = useMediaQuery((theme) => theme.breakpoints.up("xl"));
  // Determine the maxWidth based on the screen size
  const maxWidth = isXlScreen ? "xxl" : "xl";
  return (
    <>
      <AppBar
        component="nav"
        className={
          isImpersonatedMode ? "headerAppBar headerTop" : "headerAppBar"
        }
      >
        <Container maxWidth={maxWidth}>
          <Toolbar className="headerToolBar">
            <Grid container className="headerContentWrapper">
              <Grid item lg={6} md={4} sm={4} xs={7}>
                {isImpersonatedMode && <SwitchBar />}
                <Box className="logoItems">
                  <img src={OpenLetterLogoCIcon} alt="open-letter" />
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    // sx={{ mr: 2, display: { md: "none" } }}
                    className="hamburgerIcon"
                  >
                    <MenuIcon sx={{ color: "#000000" }} />
                  </IconButton>
                  <List>
                    {currentNavigation.map((item) => {
                      return (
                        <NavLink
                          key={item?.id}
                          to={item?.link}
                          className={({ isActive }) =>
                            isActive ||
                            item.childrens.includes(location.pathname)
                              ? "activeItem"
                              : ""
                          }
                        >
                          <ListItem>{item?.title}</ListItem>
                        </NavLink>
                      );
                    })}
                  </List>
                </Box>
              </Grid>
              <Grid item lg={6} md={8} sm={8} xs={5}>
                <Box className="buttonsWrapper">
                  <Mode />
                  {!isAdminMode && (
                    <Button
                      onClick={isAdmin ? handlePaymentModal : undefined}
                      className="balanceBtn"
                    >
                      {balanceFormatter(userOrganization.balance)}
                    </Button>
                  )}
                  <Box className="helpCenter avatarWrapper">
                    <img onClick={handleDropDown} src={Help2CIcon} alt="icon" />
                    {helpDropDown ? (
                      <HelpDropdown
                        setHelpDropDown={setHelpDropDown}
                        dropValue={helpDropDown}
                        onClick={onClick}
                        isWidgetVisible={isWidgetVisible}
                        setIsWidgetVisible={setIsWidgetVisible}
                      />
                    ) : (
                      ""
                    )}
                  </Box>
                  <Box className="avatarWrapper">
                    <Typography
                      sx={{
                        display: {
                          md: "block",
                          xs: "none",
                        },
                      }}
                      onClick={handleShowDropDown}
                    >
                      {userName &&
                        (userName.length > 20
                          ? `${userName.slice(0, 15)}...`
                          : userName)}
                    </Typography>
                    <img
                      src={DropdownArrrowCIcon}
                      alt="DownArrow"
                      onClick={handleShowDropDown}
                    />
                    {showDropDown ? (
                      <DropDown
                        setShowDropDown={setShowDropDown}
                        isAdminMode={isAdminMode}
                        dropValue={showDropDown}
                        userName={userName}
                      />
                    ) : (
                      ""
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          className="drawerMobileWrapper"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "block", lg: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <LimitModal />
      {fundsModal && (
        <AddFunds
          open={fundsModal}
          handleClose={() => {
            setFundsModal(false);
          }}
        />
      )}
      <Stripe>
        <PaymentMethod
          open={paymentMehodModal}
          handleClose={() => {
            setPaymentMethodModal(false);
          }}
        />
      </Stripe>
    </>
  );
};

export default Header;
